import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const PanelContactStyled = styled.section`
  margin-top: 25px;
  margin-bottom: 25px;

  @media only screen and (min-width: 700px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .pp-contact-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .pp-contact-inner {
      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 752px;

      svg {
        @media only screen and (max-width: 700px) {
          font-size: 80px;
          line-height: 90px;
        }
        font-size: 104px;
        line-height: 104px;
        color: #b71b54;
        margin-right: 30px;
      }

      .pp-contact-text {
        @media only screen and (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
        }

        font-size: 28px;
        line-height: 42px;
        color: #b71b54;

        .link {
          font-weight: 700;
          font-size: inherit;
        }
      }
    }
  }
`

export const PanelContact = ({ content, email }) => {
  return (
    <PanelContactStyled>
      <div className="pp-contact-container container">
        <div className="pp-contact-inner">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
               data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16" role="img" viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
          </svg>
          <p className="pp-contact-text">
            <span dangerouslySetInnerHTML={{ __html: content }} />
            <a className="link pink" href={`mailto:${email}`}>
              {email}
            </a>
          </p>
        </div>
      </div>
    </PanelContactStyled>
  )
}

PanelContact.propTypes = {
  content: PropTypes.string,
  email: PropTypes.string,
}
