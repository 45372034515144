import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"

const CircularButton = styled.div`
  display: inline-block;
  position: absolute;
  top: 40%;
  outline: none;
  border: none;
  width: 51px;
  height: 51px;
  z-index: 99;
  background-color: var(--color-primary);
  border-radius: 100%;
  text-align: center;
  line-height: 51px;
  font-size: 32px;
  color: var(--color-text-light);
  cursor: pointer;
  transition: all 0.15s;

  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    transform: scale(1.1);
  }
`

export const CircularButtonNext = styled(CircularButton)`
  right: 1%;
  ${breakpointsMedia(breakpoints.tablet)} {
    right: 11%;
  }

  svg {
    margin-right: -7px;
  }
`

export const CircularButtonPrev = styled(CircularButton)`
  left: 1%;

  ${breakpointsMedia(breakpoints.tablet)} {
    left: 11%;
  }

  svg {
    margin-left: -7px;
  }
`

export const CircularButtonNextOutbound = styled(CircularButton)`
  right: 0;

  svg {
    margin-right: -7px;
  }
`

export const CircularButtonPrevOutbound = styled(CircularButton)`
  left: 0;

  svg {
    margin-left: -7px;
  }
`
