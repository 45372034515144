import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Heading from "@components/ui/Heading"

const IntroductionStyled = styled.section`
  max-width: 838px;
  margin: 0 auto 70px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 0 20px;

    b,
    strong {
      font-weight: 700;
    }
  }
`

export const Introduction = ({ title, content }) => {
  return (
    <IntroductionStyled className="container">
      <Heading node="h1" underlined>
        {title}
      </Heading>
      {content && (
        <div className="content-container">
          <div
            className="content-inner"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}
    </IntroductionStyled>
  )
}

Introduction.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

Introduction.defaultProps = {
  title: `Passion Panel`,
}
