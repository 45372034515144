import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SwiperCore, { Navigation } from "swiper"
import "swiper/swiper.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  CircularButtonNextOutbound,
  CircularButtonPrevOutbound,
} from "@components/ui/slider/arrows"
import { GatsbyImage } from "gatsby-plugin-image"
import Heading from "@components/ui/Heading"

SwiperCore.use([Navigation])

const EventFeedbackStyled = styled.div`
  .event-feedback-title {
    font-size: 34px;
    line-height: 41px;
    color: #484a4b;
    font-weight: 700;
  }

  .quotes-slides {
    .slick-next,
    .slick-prev {
      @media only screen and (max-width: 700px) {
        display: none !important;
      }
      @media only screen and (min-width: 701px) and (max-width: 800px) {
        display: none !important;
      }
      background-color: var(--color-bg);
      border-radius: 0;
      top: 25%;
      padding: 0;

      i {
        margin: 0;

        &:before {
          color: #484a4b;
        }
      }
    }

    .slick-next {
      right: -90px;
    }

    .slick-prev {
      left: -90px;
    }

    .quote-slide {
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: start;
        padding-left: 20px;
      }
      padding: 0 65px;
      // for slick - to override display: inline-block
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .quote-slide-text {
        @media only screen and (max-width: 700px) {
          width: 100%;
          margin-bottom: 20px;
        }
        @media only screen and (min-width: 701px) and (max-width: 800px) {
          width: 60%;
        }
        width: 75%;

        .quote-source {
          margin: 0 0 30px;
          font-size: 20px;
          line-height: 24px;
          color: #484a4b;
          font-weight: 700;

          span {
            font-size: 20px;
            line-height: 24px;
            color: #484a4b;
            font-weight: 700;
          }
        }

        .quote-content {
          font-size: 14px;
          line-height: 17px;
          color: #484a4b;
          font-weight: 300;
        }
      }

      .quote-slide-logo {
        width: 213px;

        img {
        }
      }
    }
  }
`

export const EventFeedback = ({ title, feedback }) => {
  return (
    <EventFeedbackStyled className="previous-events-feedback">
      <Heading node="h2">{title}</Heading>

      <Swiper
        id="event-feedback"
        className="quotes-slides"
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
      >
        {feedback.map(
          ({ sourceCompany, sourceName, quote, companyLogo }, key) => {
            return (
              <SwiperSlide className="quote-slide" key={key}>
                <div className="quote-slide-text">
                  <p className="quote-source">
                    <span>{sourceName}</span> - <span>{sourceCompany}</span>
                  </p>
                  <p className="quote-content">{quote}</p>
                </div>
                <div className="quote-slide-logo">
                  <GatsbyImage
                    image={
                      companyLogo.localFile?.childImageSharp?.gatsbyImageData
                    }
                    imgStyle={{
                      objectFit: "cover",
                    }}
                  />
                </div>
              </SwiperSlide>
            )
          }
        )}
        <CircularButtonPrevOutbound className="button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left fa-w-8"
            role="img"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
            />
          </svg>
        </CircularButtonPrevOutbound>
        <CircularButtonNextOutbound className="button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-right"
            className="svg-inline--fa fa-angle-right fa-w-8"
            role="img"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
            />
          </svg>
        </CircularButtonNextOutbound>
      </Swiper>
    </EventFeedbackStyled>
  )
}

EventFeedback.propTypes = {
  title: PropTypes.string,
  feedback: PropTypes.arrayOf(
    PropTypes.shape({
      sourceCompany: PropTypes.string,
      sourceName: PropTypes.string,
      quote: PropTypes.string,
      companyLogo: PropTypes.object,
    })
  ),
}

EventFeedback.defaultProps = {}
