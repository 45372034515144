import React, { useReducer } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Heading from "@components/ui/Heading"

const AttendeesStyled = styled.section`
  margin-top: 25px;
  margin-bottom: 25px;

  @media only screen and (min-width: 700px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .attendees-title {
    font-size: 23px;
    line-height: 36px;
    color: #484a4b;
    font-weight: 700;
  }

  .attendees-list {
    @media only screen and (max-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 701px) and (max-width: 800px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    column-gap: 48px;
    justify-items: center;

    &:after {
      content: "";
      background-color: #c5c5c5;
      position: relative;
      display: block;
      width: inherit;
      height: 1px;
      //z-index: 7;
      margin-top: 7px;
    }

    .gatsby-image-wrapper {
      height: 150px;
      width: 100%;

      &.showLess {
        -webkit-animation: fadeOut 0.3s ease-in;
        -moz-animation: fadeOut 0.3s ease-in;
        opacity: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        left: -999em;
      }

      &.showMore {
        -webkit-animation: slide-down 0.3s ease-out;
        -moz-animation: slide-down 0.3s ease-out;
      }
    }
  }

  @-webkit-keyframes fadeOut {
    100% {
      opacity: 1;
      visibility: visible;
    }
    0% {
      opacity: 0;
      visibility: visible;
    }
  }
  @-moz-keyframes fadeOut {
    100% {
      opacity: 1;
      visibility: visible;
    }
    0% {
      opacity: 0;
      visibility: visible;
    }
  }
  @-webkit-keyframes slide-down {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @-moz-keyframes slide-down {
    0% {
      opacity: 0;
      -moz-transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  .see-more-attendees {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ea9622;
    font-size: 17px;
    text-decoration: underline;
    line-height: 29px;

    &:before {
      content: "";
      background-color: #c5c5c5;
      position: relative;
      width: 100%;
      height: 1px;
      display: block;
      margin-bottom: 25px;
    }
  }
`

export const Attendees = ({ title, logos, expandText, contractText }) => {
  const [isOpen, toggle] = useReducer(isOpen => !isOpen, false)
  return (
    <AttendeesStyled>
      <div className="attendees-container container">
        <div className="attendees-inner">
          {title && (
            <Heading node="h3">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Heading>
          )}
          {logos && (
            <div className="attendees-list">
              {logos.map((logo, key) => {
                let className = ""

                if (key + 1 > 6) {
                  className = isOpen ? "showMore" : "showLess"
                }

                return (
                  <CustomGatsbyImage
                    imgStyle={{
                      objectFit: "contain",
                    }}
                    key={key}
                    className={className}
                    {...logo.localFile}
                  />
                )
              })}
            </div>
          )}
          <div
            className="see-more-attendees"
            onClick={toggle}
            onKeyPress={toggle}
            role="button"
            tabIndex="0"
          >
            {isOpen ? (
              <>
                <span dangerouslySetInnerHTML={{ __html: contractText }} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-up"
                  className="svg-inline--fa fa-chevron-up fa-w-14"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                  />
                </svg>
              </>
            ) : (
              <>
                <span dangerouslySetInnerHTML={{ __html: expandText }} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-down"
                  className="svg-inline--fa fa-chevron-down fa-w-14"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                  />
                </svg>
              </>
            )}
          </div>
        </div>
      </div>
    </AttendeesStyled>
  )
}

Attendees.propTypes = {
  logos: PropTypes.array.isRequired,
}

Attendees.defaultProps = {
  expandText: `See more`,
  contractText: `See less`,
}
