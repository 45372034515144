import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import { Introduction } from "@components/sections/passion-panel/Introduction"
import HeroBlank from "@components/sections/heros/HeroBlank"
import { Attendees } from "@components/sections/passion-panel/Attendees"
import { PanelContact } from "@components/sections/passion-panel/PanelContact"
import { PassionPanelEvents } from "@components/sections/passion-panel/PassionPanelEvents"
import { EventFeedback } from "@components/sections/passion-panel/EventFeedback"

export default function PassionPanelPage({ data }) {
  const page = data.allWpPage.nodes[0]
  const previousEvents = data.previousEvents
  const upcomingEvents = data.upcomingEvents
  const {
    passionPanelContent,
    attendees,
    contactGroup,
    postsGroup,
    passionPanelQuote,
  } = page.acfPassionPanel

  const logos = attendees?.previousAttendees?.map(
    ({ attendeeLogo }) => attendeeLogo
  )

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short",
        }}
      />
      <HeroBlank />
      <Introduction content={passionPanelContent} />
      {logos && (
        <Attendees
          title={attendees.title}
          expandText={attendees?.expandText}
          logos={logos}
        />
      )}

      {(previousEvents?.nodes || upcomingEvents?.nodes) && (
        <PassionPanelEvents
          title={postsGroup.title}
          events={previousEvents.nodes}
          upcomingEvents={upcomingEvents.nodes}
        >
          {passionPanelQuote?.eventFeedback && (
            <EventFeedback
              title={passionPanelQuote.sectionTitle}
              feedback={passionPanelQuote.eventFeedback}
            />
          )}
        </PassionPanelEvents>
      )}

      <PanelContact content={contactGroup.text} email={contactGroup.email} />
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpPage(filter: {slug: {eq: $slug}}) {
    nodes {
      id
      uri
      title
      link
      content
      seo {
        schema {
          raw
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
      }
      acfPassionPanel {
        postsGroup {
          title
          expandLinkText
        }
        passionPanelQuote {
          sectionTitle
          eventFeedback {
            sourceName
            sourceCompany
            quote
            companyLogo {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 213, quality: 50, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        passionPanelContent
        contactGroup {
          email
          text
        }
        attendees {
          expandText
          title
          previousAttendees {
            attendeeLogo {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 300, quality: 50, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
  previousEvents: allWpPage(
    filter: {template: {templateName: {eq: "Passion Panel Previous"}}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      title
      uri
      date(formatString: "MM/DD/YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  upcomingEvents: allWpPage(
    filter: {template: {templateName: {eq: "Passion Panel Upcoming"}}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      title
      uri
      date(formatString: "MM/DD/YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
