import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Heading from "@components/ui/Heading"

const PreviousEventsStyled = styled.div`
  & + & {
    margin-top: 0;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  margin-bottom: 100px;
  margin-top: 100px;

  .previous-events {
    background-color: #f2f2f2;
  }

  .previous-events-container {
    padding: 30px 15px;
  }

  .previous-events-title {
    color: #484a4b;
    font-size: 34px;
    line-height: 41px;
    font-weight: 700;
  }

  .previous-events-output {
    @media only screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
    @media only screen and (min-width: 701px) and (max-width: 800px) {
      column-gap: 20px;
    }
    display: grid;
    column-gap: 50px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 45px;

    .gatsby-image-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .previous-events-output-link {
    height: 303px;
    overflow: hidden;

    .blogItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: all 0.7s ease;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &:hover {
        transform: scale(1.3) rotate(3deg);
      }

      .outputItemInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .outputTitle {
          color: var(--color-text-light);
          font-size: 28px;
          line-height: 33px;
          text-align: center;
          font-weight: 700;
          margin-top: 0;
          padding: 0;
          text-shadow: unset;
        }

        .outputDate {
          color: #ea9622;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }

  .see-more-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      background-color: #c5c5c5;
      position: relative;
      width: 100%;
      height: 1px;
      display: block;
      margin-bottom: 25px;
    }

    &:after {
      content: "\\f078";
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      color: #ea9622;
      font-size: 12px;
    }

    a {
      color: #ea9622;
      font-size: 17px;
      text-decoration: underline;
      line-height: 29px;
    }
  }
`

export const PassionPanelEvents = ({
  title,
  events,
  upcomingEvents,
  children,
}) => {
  const upcomingEventsLength = upcomingEvents.length > 0

  return (
    <PreviousEventsStyled>
      <section className="previous-events">
        <div className="previous-events-container container">
          <div className="previous-events-inner">
            {upcomingEventsLength && (
              <div className="previous-events-posts">
                <div className="previous-events-top">
                  <Heading node="h2">Upcoming Events</Heading>
                </div>
                <div className="previous-events-output">
                  {upcomingEvents.map(
                    ({ featuredImage, title, date, uri }, key) => {
                      return (
                        <div className="previous-events-output-link">
                          <Link to={uri} className="blogItem">
                            <GatsbyImage
                              image={
                                featuredImage?.node?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                              imgStyle={{
                                objectFit: "cover",
                              }}
                              style={{
                                position: "absolute",
                              }}
                            />
                            <div className="outputItemInner">
                              <p
                                className="outputTitle"
                                dangerouslySetInnerHTML={{ __html: title }}
                              />
                            </div>
                          </Link>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            )}
            <div className="previous-events-posts">
              <div className="previous-events-top">
                <Heading node="h2">{title}</Heading>
              </div>
              <div className="previous-events-output">
                {events.map(({ featuredImage, title, date, uri }, key) => {
                  return (
                    <div className="previous-events-output-link">
                      <Link to={uri} className="blogItem">
                        <GatsbyImage
                          image={
                            featuredImage?.node?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          imgStyle={{
                            objectFit: "cover",
                          }}
                          style={{
                            position: "absolute",
                          }}
                        />
                        <div className="outputItemInner">
                          <p
                            className="outputTitle"
                            dangerouslySetInnerHTML={{ __html: title }}
                          />
                          <p
                            className="outputDate"
                            dangerouslySetInnerHTML={{ __html: date }}
                          />
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            {children}
          </div>
        </div>
      </section>
    </PreviousEventsStyled>
  )
}

PassionPanelEvents.propTypes = {
  title: PropTypes.string,
}
