import React from "react"
import { HeroStyle } from "@styles/organisms/Hero"
import styled from "styled-components"

const HeroBlankStyle = styled(HeroStyle)`
  height: auto;
  min-height: auto;

  .container {
    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const HeroBlank = () => {
  return <HeroBlankStyle />
}

export default HeroBlank
